import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SidenavItem } from '../../sidenav/sidenav-item/sidenav-item.model';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers/index';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { HttpService } from 'src/app/api/http.service';
import { SearchService } from 'src/app/services/search.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { User } from 'src/app/class/user';

@Component({
  selector: 'elastic-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  isOpen: boolean;
  txt: string;
  @ViewChild('input', { read: ElementRef, static: true }) input: ElementRef;
  @ViewChild('inputElem', { static: true }) inputElem: ElementRef;

  recentlyVisited: SidenavItem[] = undefined;
  frequentlyVisited: SidenavItem[] = [];
  sidenavItems: SidenavItem[] = [];
  searchResult: SidenavItem[] = [];
  txtAnt: string;
  user: User;

  constructor(private router: Router,
    public http: HttpService,
    private store: Store<fromRoot.State>,
    private cd: ChangeDetectorRef,
    private search: SearchService,
    private st: LocalStorage) { }

  ngOnInit() {
  }

  open() {
    this.isOpen = true;

    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 100);

  }

  close() {
    this.isOpen = false;
  }

  move() {
    this.setCode("@" + this.inputElem.nativeElement.value);
  }

  addToKeywords() {

    this.setCode("@" + this.inputElem.nativeElement.value);
  }

 

  setCode(code: string) {

    this.search.change(code);
  }

}
