import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { User } from 'src/app/class/user';
import { HttpService } from 'src/app/api/http.service';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
  selector: 'elastic-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  user: User;
  remember: boolean;

  constructor(
    private router: Router,
    public http: HttpService,
    private storage: LocalStorage
  ) { }

  ngOnInit() {
    this.remember = false;
    this.storage.getItem('credentials').subscribe((credentials) => {
      console.log(credentials);
      if (credentials !== null) {
      this.email = credentials['email'];
      this.password = credentials['password'];
      this.remember = true; }
    });
  }

  login() {
    this.getUser();
    //this.router.navigate(['/']);
  }

  getUser() {

    this.user = new User();
    this.user.email = this.email;
    this.user.pass = this.password;

    this.http.getValidate(this.user)
      .subscribe((res: User) => {
        this.user = res;
        console.log(this.user);

        if (this.user !== undefined && this.user.iduser > 0) {
          this.storage.setItem('user', this.user).subscribe(() => {

            console.log(this.remember);
            if (this.remember) {
              
              this.storage.setItem('credentials', { 'email': this.email, 'password': this.password }).subscribe(() => { });
              console.log('grabo');
            } else {
              this.storage.removeItem('credentials').subscribe(() => {});
  
            }
            this.router.navigate(['/']).then(()=>{
              window.location.reload();
            });
           
            //   this.router.navigate(['/tabs']);


           });
          
        } else {
          alert("Your email or password are incorrect");

        }



        // this.isLoadingResults = false;
      }, err => {
        console.log(err);
        // this.isLoadingResults = false;
      });



  }

}
